import moment from 'moment-timezone';
import { DATE_FORMAT } from './Constants';
import { NormalFormat } from './NumberFormat';
import Cookies from "universal-cookie";
import jwt_decode from "jwt-decode";
const getCurrentDate = (format = DATE_FORMAT[1]) => {
  return moment(new Date()).tz('America/Los_Angeles').format(format);
};

const setToken = (token) => {
  return localStorage.setItem("accessToken", token);
  // const cookies = new Cookies();
  // cookies.set("accessToken", token, { maxAge: 60000 });
  // cookies.endTime(new Date(Date.now() + 1000 * 60 * 60 * 24 * 7));
};

const getToken = () => {
  return localStorage.getItem("accessToken")
  // const cookies = new Cookies();
  // return cookies.get("accessToken");
};

const isFreeTrailSymbols = (symbol) => {
  // const decodedToken = getToken() ? jwt_decode(getToken()) : {};
  // let symbols = ["AMZN", "AAPL", "BRK-A", "GOOGL", "META", "MSFT", "NVDA", "JPM", "TSLA", "NFLX"]
  // const isIncludes = symbols.includes(symbol);
  // if (decodedToken?.prime_user) return true;
  // if (isIncludes && decodedToken?.trail) {
  //   return true;
  // }
  // else return false;
  return true
}

const getPrimeUser = () => {
  return true
  // const decodedToken = getToken() ? jwt_decode(getToken()) : {};
  // return decodedToken?.prime_user
}
const getTrailDays = () => {
  const decodedToken = getToken() ? jwt_decode(getToken()) : {};
  return decodedToken?.trail_days
}

const getTrail = () => {
  const decodedToken = getToken() ? jwt_decode(getToken()) : {};
  return decodedToken?.trail
}

const getDecodeToken = () => {
  const decodedToken = getToken() ? jwt_decode(getToken()) : {};
  let _obj = { ...decodedToken };
  _obj["id"] = decodedToken.user_id;
  return _obj
}

const isPrimeAndOpn = () => {
  return getToken() && getPrimeUser()
}

const getAdmin = () => {
  const decodedToken = getToken() ? jwt_decode(getToken()) : {};
  return decodedToken?.is_admin;
}
const isAdmin = () => {
  return getToken() && getAdmin()
}

const removeToken = () => {
  return localStorage.removeItem("accessToken");

};

const getOneDayBeforeDate = (format = DATE_FORMAT[1]) => {
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  return moment(yesterday).tz('America/Los_Angeles').format(format);
};

const getNDayBeforeDate = (dayBefore) => {
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - dayBefore);
  return moment(yesterday).tz('America/Los_Angeles').format('YYYY/MM/DD');
};

function capitalizeFirstLetter(string) {
  return string ? string.charAt(0).toUpperCase() + string.slice(1) : '';
}

const replaceEmpty = (value) => {
  if (value === '' || value === null || value === undefined) {
    return '-';
  } else {
    return value;
  }
};

const replaceEmptyWithPostFix = (value, postFix = '%') => {
  if (value === '' || value === null || value === undefined) {
    return '-';
  } else {
    return `${value} ${postFix ? postFix : ''}`;
  }
};

const replaceEmptyWithPreFix = (value, preFix = '$') => {
  if (value === '' || value === null || value === undefined) {
    return '-';
  } else {
    return `${preFix ? preFix : ''} ${value}`;
  }
};

const replaceEmptyWithNumberPreFix = (value, preFix = '$') => {
  if (value === '' || value === null || value === undefined) {
    return '-';
  } else {
    return `${preFix ? preFix : ''} ${NormalFormat(value)}`;
  }
};

const millionToBillionConvert = (number) => {
  return number ? `${(number / 1000).toFixed(2)} B` : '-';
};

const convertCamelCaseToSpaceSeparatedString = (text) => {
  if (text) {
    const result = text.replace("TTM","").replace(/([A-Z])/g, ' $1');
    const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
    return finalResult;
  } else {
    return '';
  }
};

function camelCase(str) {
  return str?.replaceAll('_'," ")
  }

function underScore(str) {
  return str?.replaceAll(' ',"_")
}
function dash(str) {
  return str?.replaceAll(' ',"-")
}

function removeDash(str) {
  return str?.replaceAll('-'," ")
}

const convertCamelCaseToUnderscoreSeparatedString = (text) => {
  if (text) {
    const result = text.replace(/([A-Z])/g,'$1');
    const check = result.replaceAll(" ","_")
    return check;
  } else {
    return '';
  }
};

const capitalizeFirstLetterOfEachWord = (str) => {
  var splitStr = str.toLowerCase().split(' ');
  for (var i = 0; i < splitStr.length; i++) {
    // You do not need to check if i is larger than splitStr length, as your for does that for you
    // Assign it back to the array
    splitStr[i] =
      splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  // Directly return the joined string
  return splitStr.join(' ');
};

const debounce = (func, timeout = 300) => {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
};


// const getToken = () => {
//   return localStorage.getItem('token');
// };

function generateId(length) {
  var result = '';
  var characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

const getTextColorByValue = (val) => {
  let color = ''
  if (val) {
    if (val > 0) {
      color = 'up-color'
    } else if (val < 0) {
      color = 'down-color'
    }
  }
  return color
}

const isMarketOpen = () => {
  var currentTime = moment(new Date()).utc()
  var startTime = moment('1:30p', 'HH:mm a').utc()
  var endTime = moment('08:00p', 'HH:mm a').utc()

  const active = currentTime.isBetween(startTime, endTime)
  return active
}

const dcfSegmentCategoryWiseTotal = (categoryLists, segmentData) => {
  let finalTotalLists = [];
  if (segmentData && segmentData?.length > 0) {
    for (let k=0; k<segmentData?.length; k++) {
      for (const [key, value] of Object.entries(segmentData[k])) {
        if (categoryLists.includes(key)) {
          const findIndex = finalTotalLists.findIndex((item) => item.name === key);
          if (findIndex === -1) {
            finalTotalLists.push({
              name: key,
              value: Number(value)
            });
          } else {
            let findSegmentRecord = finalTotalLists[findIndex];
            let finalValue = Number(findSegmentRecord.value) + Number(value);
            finalTotalLists[findIndex]["value"] = finalValue;
          }
        }
      }
    }
  }
  return finalTotalLists;
}

const PriceChartCalculation = (growthValue, priceValue, currentValue) => {
  let growth = Number(growthValue);
  let price = Number(priceValue);
  let current = Number(currentValue);
  if (growth !== 0) {
    let calculateValue = (price - current)/current;
    return Number((calculateValue*100).toFixed(2));  
  } else {
    return 0;
  }
  

}

const revenueInPercentage = (serviceValue, totalValue) => {
  let finalValue = (serviceValue/totalValue) * 100;
  return finalValue.toFixed(2)
}

export {
  getCurrentDate,
  getOneDayBeforeDate,
  capitalizeFirstLetter,
  replaceEmpty,
  millionToBillionConvert,
  replaceEmptyWithPostFix,
  replaceEmptyWithPreFix,
  replaceEmptyWithNumberPreFix,
  convertCamelCaseToSpaceSeparatedString,
  convertCamelCaseToUnderscoreSeparatedString,
  camelCase,
  underScore,
dash,
  getNDayBeforeDate,
  capitalizeFirstLetterOfEachWord,
  debounce,
  setToken,
  getToken,
  removeToken,  
  generateId,
  getTextColorByValue,
  isMarketOpen,
  dcfSegmentCategoryWiseTotal,
  revenueInPercentage,
  PriceChartCalculation,
  getPrimeUser,
  isPrimeAndOpn,
  getDecodeToken,
  removeDash,
  isAdmin,
  isFreeTrailSymbols,
  getTrail,
  getTrailDays
}
